import React from 'react';
import './features.css';

function Features() {
  return (
    <section id="features">
      <div className="container">
        <div className="row text-center">
          <div className="titulo">
            <h1 className="titulo-h1">Nossas Soluções</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2 feature-box">
            <i className="icon fas fa-car fa-5x"></i>
            <h3>Carros</h3>
          </div>

          <div className="col-lg-2 feature-box">
            <i className="icon fas fa-motorcycle fa-5x"></i>
            <h3>Motos</h3>
          </div>

          <div className="col-lg-2 feature-box">
            <i className="icon fas fa-truck fa-5x"></i>
            <h3>Caminhões</h3>
          </div>

          <div className="col-lg-2 feature-box">
            <i className="icon fas fa-tractor fa-5x"></i>
            <h3>Máquinas</h3>
          </div>

          <div className="col-lg-2 feature-box">
            <i className="icon fas fa-box fa-5x"></i>
            <h3>Cargas</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;