import React from 'react';
import Menu from './Components/menu/menu.jsx';
import Banner from './Components/banner/banner.jsx';
import Features from './Components/features/features.jsx';
import Recursos from './Components/recursos/recursos.jsx';
import Footer from './Components/footer/footer.jsx';
import Whatsapp from './Components/whatsapp/whatsapp.jsx';


function Site(){
    return <div>
      <Menu/>
      <Banner/>
      <Features/>
      <Recursos/>
      <Whatsapp/>
      <Footer/>
    </div>;
  }

export default Site;