import React from 'react';
import "./banner.css";

function Banner(){
    return (
            <section id="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>Rede Rastreadores</h1>
                            <h4>Nossa <strong>Plataforma</strong> oferece soluções para gestão da frota, proporcionando controle e otimização de custos.</h4>
                            <a href="https://api.whatsapp.com/send?phone=5577999685128" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-lg btn-outline-primary">Contrate nosso Rastreador!</button>
                            </a>    
                        </div>
                        <div className="img-col-lg-6">
                            <img src="Images/screenshot-crm.png" alt="Banner Inicial" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

export default Banner;