import React, { useState } from 'react';
import './menu.css';

const Menu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`navbar-fixed${isMenuOpen ? ' open' : ''}`}>
      <div className="navbar">
        <img src="Images/logo.png" alt="Logo" className="logo" />
        <div className={`menu-icon${isMenuOpen ? ' open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`menu-list${isMenuOpen ? ' open' : ''}`}>
          <li><a href="#banner" onClick={toggleMenu}>Home</a></li>
          <li><a href="#features" onClick={toggleMenu}>O que Rastreamos</a></li>
          <li><a href="#recursos" onClick={toggleMenu}>Recursos</a></li>
          <li><a href="https://api.whatsapp.com/send?phone=5577999685128" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Contato</a></li>
          <li><a href="http://www16.itrack.com.br/rederastreadores/" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Monitoramento</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;