import React from 'react';
import './whatsapp.css';

const WhatsAppButton = () => {
  return (
    <div className="whatsapp-button-container">
      <a
        href="https://api.whatsapp.com/send?phone=5577999685128"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src="Images/whatsapp.png" alt="WhatsApp"
        />
      </a>
    </div>
  );
};

export default WhatsAppButton;