import React from 'react';
import './footer.css';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
      <div>
            <ul class="list-unstyled list-inline social text-center">
              <li class="list-inline-item">
                  <a href="https://www.instagram.com/niltoncruz10/" target="_blank" rel="noopener noreferrer"><i class="fa fa-instagram fa-2x"></i></a>   
              </li>	
              <li class="list-inline-item"><a href="mailto:contato@rederastreadores.com" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-envelope fa-2x"></i></a></li>
            </ul>
	    </div>
      {/* <p>by niltoncruz10- {ano}</p>*/}
        {<a href="https://api.whatsapp.com/send?phone=5577999714632" target="_blank" rel="noopener noreferrer">by niltoncruz10- {ano}</a>}
    </section>;
  }

export default Footer;