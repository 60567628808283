import React from 'react';
import './recursos.css';

function Recursos(){
    return <section id="recursos">
        <div className="container">

            <div className="row text-center">
                <div className="titulo">
                    <h1 className="recursos">Recursos</h1>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3>Para você</h3>
                        </div>
                        <div className="card-body">
                        
                            <ul class="list-group">
                                <li class="list-group-item">Segurança pessoal</li>
                                <li class="list-group-item">Manutenção Preventiva</li>
                                <li class="list-group-item">Registro de Históric</li>
                                <li class="list-group-item">Controle de velocidade</li>
                                <li class="list-group-item">Manutenção preventiva</li>
                                <li class="list-group-item">Localização em tempo real</li>
                                <li class="list-group-item">Controle de uso do veículo</li>
                                <li class="list-group-item">Redução de custos de seguro</li>
                                <li class="list-group-item">Recuperação em caso de roubo</li>
                                <li class="list-group-item">Relatórios diversos</li>
                                <li class="list-group-item">E muito mais!</li>
                            </ul>

                            <a href="https://api.whatsapp.com/send?phone=5577999685128" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-lg btn-outline-primary">Para mais informações</button>
                            </a> 
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3>Para sua Empresa</h3></div>
                        <div className="card-body">

                             <ul class="list-group">
                                <li class="list-group-item">Otimização de Rotas</li>
                                <li class="list-group-item">Melhoria na Segurança</li>
                                <li class="list-group-item">Manutenção Preventiva</li>
                                <li class="list-group-item">Cumprimento de Prazos</li>
                                <li class="list-group-item">Registro de Histórico</li>
                                <li class="list-group-item">Controle de velocidade</li>
                                <li class="list-group-item">Aumento da Produtividade</li>
                                <li class="list-group-item">Localização em tempo real</li>
                                <li class="list-group-item">Redução de Custos</li>
                                <li class="list-group-item">Relatórios diversos</li>
                                <li class="list-group-item">E muito mais!</li>
                            </ul>

                            <a href="https://api.whatsapp.com/send?phone=5577999685128" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-lg btn-outline-primary">Para mais informações</button>
                            </a> 
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>;
  }

export default Recursos;